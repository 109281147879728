import React from "react";

const Home = () => {
  return (
    <React.StrictMode>
      <div className="main-page-content">
        <div id="home">
          <div id="particles-js"></div>
          <div className="home-content-main">
            <div className="table-cell">
              <div className="container">
                <div className="row home-row">
                  <div className="col-md-12 col-sm-12">
                    <div className="home-text wow fadeIn text-center">
                      <h1 className="cd-headline clip is-full-width">
                        <span
                          className="cd-words-wrapper"
                          style={{ width: "266px", overflow: "hidden" }}
                        >
                          <b className="is-hidden">Baye Mbaye LO</b>
                          <b className="is-hidden">Développeur</b>
                          <b className="is-hidden">Mobile</b>
                          <b className="is-visible">Web</b>
                        </span>
                      </h1>
                      <div className="about-social-icon text-center">
                        <ul className="about-social">
                          <li className="wow fadeIn" data-wow-delay=".4s">
                            <a
                              href="https://www.linkedin.com/in/bayembayelo/"
                              target="newtab"
                            >
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li className="wow fadeIn" data-wow-delay=".2s">
                            <a
                              href="https://github.com/bacarlo"
                              target="newtab"
                            >
                              <i
                                className="fa fa-github"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li className="wow fadeIn" data-wow-delay=".6s">
                            <a
                              href="https://www.instagram.com/bacar_bml/"
                              target="newtab"
                            >
                              <i
                                className="fa fa-instagram"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li className="wow fadeIn" data-wow-delay=".8s">
                            <a
                              href="https://x.com/bacar_bml"
                              target="newtab"
                            >
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li className="wow fadeIn" data-wow-delay="1s">
                            <a
                              href="https://bckonnectel.com"
                              target="newtab"
                            >
                              <i className="fa fa-globe" aria-hidden="true"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="parallax" data-velocity="-.1"></div>
          <div className="parallax" data-velocity="-.5" data-fit="525"></div>
        </div>

        {/* <!-- ================================ ABOUT =============================== --> */}

        <div id="about">
          <div className="about-content">
            <div className="love-grid text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Ce que je fais</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Je suis un développeur passionné par la création d'expériences web exceptionnelles. Ma mission est de donner vie à des idées digitales en combinant créativité, innovation et compétences techniques. Je crois en la puissance de la conception web pour captiver les utilisateurs et créer des interfaces conviviales.
                      Mon voyage dans le monde du développement web a commencé il y a cinq ans, et depuis, j'ai eu la chance de travailler sur une variété de projets. Mon approche est axée sur la simplicité et la convivialité. Je m'efforce de créer des sites web réactifs, intuitifs et esthétiquement agréables.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".1s">
                      <i
                        className="fa fa-pencil-square-o love-icon"
                        aria-hidden="true"
                      ></i>
                      <h3>Sites Vitrine</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Présenter une entreprise, un produit ou un service.</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".3s">
                      <i
                        className="fa fa-file-image-o love-icon"
                        aria-hidden="true"
                      ></i>
                      <h3>Sites Institutionnels</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Fournir des informations sur une organisation ou une institution.</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".2s">
                      <i
                        className="fa fa-globe love-icon"
                        aria-hidden="true"
                      ></i>
                      <h3>Sites de Portail</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Servir de point d'accès centralisé à des informations variées.</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                      <i className="fa fa-cog love-icon" aria-hidden="true"></i>
                      <h3>Développement Web</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Création de tous les types de site Web moderne.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="about-back"></div>
            <div className="me-grid">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-12 about-col">
                    <div className="about-image">
                      <img
                        src="assets/img/BML.jpg"
                        alt="Baye Mbaye LO"
                        className="about-img wow fadeIn"
                      />
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-6 col-xs-12 about-col">
                    <div className="about-details wow fadeIn">
                      <div className="main-title left-title text-left wow fadeIn">
                        <h3>Salut! C'est Baye Mbaye </h3>
                        <div className="underline1 no-margin"></div>
                        <div className="underline2 no-margin"></div>
                      </div>
                      <p className="wow fadeIn">
                        Je suis un développeur Web de Dakar, au Sénégal. J'aime tout créer, des sites de petites entreprises aux applications Web interactives riches. si vous êtes une entreprise cherchant une présence sur le Web ou un employeur cherchant à embaucher, vous pouvez me contacter{" "}
                        <a className="underline2" href="#contact">
                          {" "}
                          ici.
                        </a>{" "}
                        Je conçois et construis des solutions digitales avec un code simple et beau. Je me spécialise dans le développement de thèmes Web personnalisés et j’aime ce que je fais.
                        <br />
                        <br />
                        Depuis que j'ai commencé mon parcours en tant que développeur Web pendant mes études universitaires, j'ai travaillé à distance pour des agences, consulté pour des startups et collaboré avec des personnes talentueuses pour créer des produits numériques destinés à la fois aux entreprises et aux consommateurs. Je suis tranquillement confiant, naturellement curieux et je travaille perpétuellement à l'amélioration de mes capacités.
                      </p>
                      <a
                        className="about-link-1"
                        href="assets/cv/cv_bayembayeFull.pdf"
                        target="_blank"
                      >
                        Voir mon CV
                      </a>
                      <a className="about-link-2" href="#contact">
                        Contactez-moi
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="work-counter text-center">
              <div className="counter">
                <div className="container">
                  <div className="row wow fadeIn">
                    <div className="col-md-3 col-sm-6">
                      <div className="work-statistics">
                        <i
                          className="fa fa-pencil-square-o stat-icon"
                          aria-hidden="true"
                        ></i>
                        <h3 className="Count">005</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>Site déja réalisé</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="work-statistics">
                        <i
                          className="fa fa-crop stat-icon"
                          aria-hidden="true"
                        ></i>
                        <h3 className="Count">002</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>En cours de réalisation</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="work-statistics">
                        <i
                          className="fa fa-bolt stat-icon"
                          aria-hidden="true"
                        ></i>
                        <h3 className="Count">002</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>Pour un client</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="work-statistics">
                        <i
                          className="fa fa-coffee stat-icon"
                          aria-hidden="true"
                        ></i>
                        <h3 className="Count">003</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>En entreprise</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ================================ Skill =============================== --> */}

        <div id="skill">
          <div className="skill-main">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-title text-center wow fadeIn">
                    <h3>Mes Compétences</h3>
                    <div className="underline1"></div>
                    <div className="underline2"></div>
                    <p>
                      J'apprécie la structure de contenu simple, les modèles de conception épurés et les interactions réfléchies.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row love-row wow fadeIn">
                <div className="col-md-6">
                  <div className="skill-details text-left">
                    <p>
                    En tant que développeur front-end, je m'engage à planifier et à itérer la structure et la mise en page des sites web pour assurer une architecture de l'information optimale. Une fois cette base solide établie, je conçois la couche visuelle pour offrir une expérience utilisateur agréable et intuitive.
                    Mon travail de développement front-end consiste à réaliser les composants visuels d'un site web en utilisant HTML, CSS et JavaScript, garantissant ainsi des sites web rapides et interactifs. Cela inclut également l'intégration de CMS, d'API et d'autres solutions technologiques pour répondre aux besoins spécifiques de chaque projet.
                    Ce qui me passionne le plus est le travail de type 0-1 : vous accompagner pour transformer votre idée en réalité et la présenter au monde. Ensemble, nous pourrons donner vie à vos concepts innovants et les rendre accessibles à votre audience cible.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="skill-details">
                    <div className="content">
                      <div className="col">
                        <ul id="skill-main">
                          <li>
                            <h3>HTML / CSS - 97%</h3>
                          </li>
                          <li>
                            <h3>Angular - 42%</h3>
                          </li>
                          <li>
                            <h3>React JS - 85%</h3>
                          </li>
                          <li>
                            <h3>PHP (Laravel) - 80%</h3>
                          </li>
                          <li>
                            <h3>React Native - 75%</h3>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ================================ RESUME =============================== --> */}

        <div id="resume">
          <div className="resume-content">
            <div className="resume-grid text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Parcours academique</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        J'aime coder des choses à partir de zéro et j'aime donner vie aux idées dans le navigateur.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-4 col-sm-4">
                    <div className="resume-details" data-wow-delay=".2s">
                      <div className="resume-hover"></div>
                      <div className="resume-main">
                        <i
                          className="fa fa-home resume-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Baccalauréat</h3>
                        <h2>2010-2017</h2>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          J'ai terminé mon cycle secondaire au Lycée Seydina Issa Rohou Lahi (LSIRL ex LPA)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="resume-details" data-wow-delay=".3s">
                      <div className="resume-hover"></div>
                      <div className="resume-main">
                        <i
                          className="fa fa-graduation-cap resume-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Licence</h3>
                        <h2>2018-2021</h2>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          J'ai obtenu mon diplôme à l'{" "}
                          <a
                            href="https://www.ipd.sn/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            IPD (Institut Polytechnique de Dakar) THomas Sankara
                          </a> <br />
                          Adresse: N°8477, Sud-Foire Dakar, Près du SAMU Municipal.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="resume-details" data-wow-delay=".4s">
                      <div className="resume-hover"></div>
                      <div className="resume-main">
                        <i
                          className="fa fa-graduation-cap resume-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Formation</h3>
                        <h2>Mars - Octobre 2023</h2>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Je suis passé par une école formation pour une durée 6 mois en front-end{" "}
                          <a
                            href="https://www.bakeli.tech/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Bakeli School of Technology
                          </a>
                          , Terrain de Foot, HLM Grand Yoff Villa 241 A côté du terminus AIBD
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row main-row wow fadeIn">
                  <div className="col-md-12">
                    <div className="main-title text-center">
                      <h3>Experience professionnel</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Je n'aime pas me définir par le travail que j'ai accompli. Je me définis par le travail que je souhaite faire.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row">
                  <div className="col-md-6 col-sm-12">
                    <div className="exp-details" data-wow-delay=".2s">
                      <div className="exp-hover"></div>
                      <div className="exp-main">
                        <i
                          className="fa fa-building exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>
                          <a
                            href="https://www.awabio.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#4d4d4d" }}
                          >
                            AwA Bio Foods
                          </a>
                        </h3>
                        <h4>Developpeur web</h4>
                        <h2>Novembre 2023 - Février 2024</h2>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                        Chez AwA Bio Foods, je travaillais en tant que stagiaire développeur Web 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="exp-details" data-wow-delay=".3s">
                      <div className="exp-hover"></div>
                      <div className="exp-main">
                        <i
                          className="fa fa-building exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>
                          <a
                            href="https://www.xiifxamxam.net/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#4d4d4d" }}
                          >
                            Xiif Xam Xam
                          </a>
                        </h3>
                        <h4>Développeur Web</h4>
                        <h2>Mars - Juillet 2024</h2>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          À Xiif Xam Xam, je suis actuellement stagiaire développeur Web qui prend fin au mois de Juillet
                          {/* &amp; Consultant informatique */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="exp-details" data-wow-delay=".4s">
                      <div className="exp-hover"></div>
                      <div className="exp-main">
                        <i
                          className="fa fa-cogs exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Développeur Freelance</h3>
                        <h4>Développeur Web &amp; Mobile</h4>
                        <h2>2021 - Présent</h2>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Je suis capable de développer des applications web, des sites internet dynamiques et des applications mobiles répondant aux besoins spécifiques de mes clients. Mon approche flexible me permet de travailler de manière efficace en collaboration directe avec mes clients, assurant ainsi des résultats personnalisés et de haute qualité.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ================================ WORK =============================== --> */}

        <div id="work">
          <div className="work-content">
            <div className="work-grid">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Voici mes réalisations</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Voici quelques projets de conception sur lesquels j'ai travaillé récemment. Vous voulez en voir plus ?{" "}
                        <a className="underline2" href="#contact">
                          contactez-moi.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="gallery" style={{ paddingTop: "80px" }}>
                  {/* BC Konnectel */}
                  <a 
                    href="https://bckonnectel.com/"
                    target="_blank"
                  >
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="assets/img/bcKonnectel.png"
                        alt="BC Konnectel"
                      />
                    </div>
                  </a>
                  {/* Xiif Xam Xam */}
                  <a 
                    href="https://www.xiifxamxam.net/"
                    target="_blank"
                  >
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="assets/img/xiifxamxam.png"
                        alt="Xiif Xam Xam"
                      />
                    </div>
                  </a>
                </div>
                <div className="gallery" style={{ paddingTop: "80px" }}>
                  {/* ÇaCré */}
                  <a 
                    href="https://www.cacre.net/"
                    target="_blank"
                  >
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="assets/img/cacre.png"
                        alt="ÇaCré"
                      />
                    </div> 
                  </a>
                  {/* Adaptech Solutions */}
                  <a 
                    href="https://adaptechsolutions.org/"
                    target="_blank"
                  >
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="assets/img/adaptech.png"
                        alt="Adaptech Solutions"
                      />
                    </div>
                  </a>
                </div>
                <div className="gallery" style={{ paddingTop: "80px" }}>
                  {/* Yelian voyage */}
                  <a 
                    href="https://yelian-voyage-etudes.com/"
                    target="_blank"
                  >
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="assets/img/yelian.png"
                        alt="Yelian"
                      />
                    </div> 
                  </a>
                  {/* <a 
                    href="https://adaptechsolutions.org/"
                    target="_blank"
                  >
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="assets/img/adaptech.png"
                        alt="Adaptech Solutions"
                      />
                    </div>
                  </a> */}
                </div>
                {/* <div className="gallery" style={{ paddingTop: "80px" }}>
                  <a 
                    href="#"
                  >
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="https://images.unsplash.com/photo-1514041181368-bca62cceffcd?w=500&h=500&fit=crop"
                        alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night"
                      />
                    </div>
                  </a>
                  <a 
                    href="#"
                  >
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="https://images.unsplash.com/photo-1514041181368-bca62cceffcd?w=500&h=500&fit=crop"
                        alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night"
                      />
                    </div>
                  </a>
                  <a 
                    href="#"
                  >
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="https://images.unsplash.com/photo-1514041181368-bca62cceffcd?w=500&h=500&fit=crop"
                        alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night"
                      />
                    </div>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ============================================== SERVICE ===================================================== --> */}

        <div id="service">
          <div className="service-content">
            <div className="service-grid text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Domaines d'intervention</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Je veux toujours faire des choses qui font la différence.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/design-development.jpg"
                          alt="design-development"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-edit service-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Application web</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                        Offrir des fonctionnalités interactives et dynamiques via le web.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/e-commarce.jpg"
                          alt="e-commarce"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-cart-plus exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>e-Commerce</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                        Intégration de plateformes de e-commerce, de moyens de paiement, de modèles de produits personnalisés, etc.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/analytics.jpg"
                          alt="analytics"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-tachometer service-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Analytique</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                        Obtenez des informations sur les personnes qui parcourent votre site afin que vous puissiez prendre des décisions commerciales plus judicieuses.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/mobile-friendly.jpg"
                          alt="mobile-friendly"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-desktop exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Adapté aux mobiles</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                        Un design responsive rend votre site Web accessible à tous les utilisateurs, quel que soit leur appareil.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/website-audit.jpg"
                          alt="website-audit"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-search exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Référencement site web</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Vous cherchez à améliorer les performances de votre page, votre référencement ou votre utilisateur expérience? Demandez un audit de site gratuit.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/content-management.jpg"
                          alt="content-management"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-file exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Gestion de vos contenus</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                        Développement de thèmes et solutions personnalisés. Mettez facilement à jour le contenu du site grâce à la connaissance d’un code puissant.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="testimonial">
              <div className="testimonial-content">
                <div className="testimonial-grid">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="main-title text-center wow fadeIn">
                          <h3>Testimonials</h3>
                          <div className="underline1"></div>
                          <div className="underline2"></div>
                          <p>
                            People I've worked with have said some nice things
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-details">
                    <section id="carousel">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-8 col-md-offset-2">
                            <div className="quote">
                              <i className="fa fa-quote-left fa-4x"></i>
                            </div>
                            <div
                              className="carousel slide"
                              id="fade-quote-carousel"
                              data-ride="carousel"
                              data-interval="3000"
                            >
                              <ol className="carousel-indicators">
                                <li
                                  data-target="#fade-quote-carousel"
                                  data-slide-to="0"
                                  className="active"
                                ></li>
                                <li
                                  data-target="#fade-quote-carousel"
                                  data-slide-to="1"
                                ></li>
                                <li
                                  data-target="#fade-quote-carousel"
                                  data-slide-to="2"
                                ></li>
                              </ol>
                              <div className="carousel-inner">
                                <div className="active item">
                                  <blockquote>
                                    <p>
                                      “Sanajit was a real pleasure to work with
                                      and we look forward to working with him
                                      again. He’s definitely the kind of
                                      developer you can trust with a project
                                      from start to finish.”
                                      <br />
                                      <span>Ishrak Chaudhury</span>
                                    </p>
                                  </blockquote>
                                </div>
                                <div className="item">
                                  <blockquote>
                                    <p>
                                      “Sanajit's a clear communicator with the
                                      tenacity and confidence to really dig in
                                      to tricky design scenarios and the
                                      collaborative friction that's needed to
                                      produce excellent work.”
                                      <br />
                                      <span>Kamrul Roy</span>
                                    </p>
                                  </blockquote>
                                </div>
                                <div className="item">
                                  <blockquote>
                                    <p>
                                      “Sanajit has done a fantastic job overall.
                                      Not only the site is to design, but the
                                      code is also very clean and slick. Love
                                      the way he achieved the translations
                                      portion of the site.”
                                      <br />
                                      <span>Shahadat Mahapatra</span>
                                    </p>
                                  </blockquote>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {/* <!-- ================================ BLOG ========================== --> */}

        {/* <div id="blog">
          <div className="blog-content">
            <div className="blog-grid">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>My Blog</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        The Blog, Which has thoughts on life, work and
                        everything in between.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-details wow fadeIn text-left">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 col-sm-6">
                      <div className="blog-main">
                        <a
                          href="https://dev.to/sanajitjana/what-is-hooks-in-react-js-41l2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="assets/img/blog/blog1.png"
                            alt="React hooks were introduced in version 16.8"
                            className="blog-img"
                          />
                        </a>
                        <div className="blog-head">
                          <a
                            href="https://dev.to/sanajitjana/what-is-hooks-in-react-js-41l2"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            <h3>
                              <strong>
                                What is the importance of hooks in React JS?
                              </strong>
                            </h3>
                          </a>
                        </div>
                        <div className="blog-bottom">
                          <p>
                            In this article, you will learn what are hooks in
                            React JS? and when to use react hooks? React JS is
                            developed by Facebook in the year 2013. There are
                            many students and the new developers who have
                            confusion between react and hooks in react. Well, it
                            is not different, react is a programming language
                            and hooks is ...
                          </p>
                          <a
                            href="https://dev.to/sanajitjana/what-is-hooks-in-react-js-41l2"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="about-link-2"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="blog-main">
                        <a
                          href="https://dev.to/sanajitjana/the-importance-of-ui-ux-software-engineering-1n0n"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="assets/img/blog/blog2.png"
                            alt="The importance of UI/UX"
                            className="blog-img"
                          />
                        </a>
                        <div className="blog-head">
                          <a
                            href="https://dev.to/sanajitjana/the-importance-of-ui-ux-software-engineering-1n0n"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            <h3>
                              <strong>
                                The importance of UI/UX | Software Engineering
                              </strong>
                            </h3>
                          </a>
                        </div>
                        <div className="blog-bottom">
                          <p>
                            UI or User Interface is the interface that is the
                            access point where users interact with computers. It
                            is also a way through which users can interact with
                            a website or an application. UI design typically
                            refers to graphical user interfaces but also
                            includes others, such as voice-controlled ones, a
                            keyboard and the appearance ...
                          </p>
                          <a
                            href="https://dev.to/sanajitjana/the-importance-of-ui-ux-software-engineering-1n0n"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="about-link-2"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="blog-main">
                        <a
                          href="https://dev.to/sanajitjana/form-example-in-laravel-8-45oc"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="assets/img/blog/blog3.png"
                            alt="blog-img"
                            className="blog-img"
                          />
                        </a>
                        <div className="blog-head">
                          <a
                            href="https://dev.to/sanajitjana/form-example-in-laravel-8-45oc"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            <h3>
                              <strong>Form Example in Laravel 8</strong>
                            </h3>
                          </a>
                        </div>
                        <div className="blog-bottom">
                          <p>
                            Laravel 8 form example tutorial. In this post, i
                            will teach from starting on how to send form data on
                            controller and how to insert form data in database
                            using laravel 8. If you are trying to create form
                            and want to insert form data into database using
                            laravel 8 latest version. So this post will help you
                            to do this. Because in this post example, i will
                            create contact-list ...
                          </p>
                          <a
                            href="https://dev.to/sanajitjana/form-example-in-laravel-8-45oc"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="about-link-2"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                      <a
                        href="https://dev.to/sanajitjana"
                        target="newtab"
                        className="btn btn-warning"
                        style={{
                          width: "50%",
                          fontSize: "16px",
                          backgroundColor: "#efd236",
                          borderColor: "#efd236",
                          color: "black",
                        }}
                      >
                        More Blog
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- ================================ CONTACT ========================== --> */}

        <div id="contact">
          <div className="contact-content">
            <div className="text-grid">
              <div className="text-grid-main">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="main-title text-center wow fadeIn">
                        <h3>Contactez-moi</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Je suis toujours disponible pour discuter de projet ou une opportunité de partenariat.
                          Vous pouvez me contacter directement par ce numéro de téléphone ou par mon mail qui sont disponible ci-dessous
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-grid">
              <div className="contact-form-details wow fadeIn">
                <div className="container">
                  {/* <div className="row contact-row">
                    <div className="col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-1 contact-col">
                      <h3>Write Your Message Here</h3>
                      <div className="contact-form">
                        <form
                          action="https://herokuapp.us6.list-manage.com/subscribe/post?u=870e1b447d1f93893227a9c52&amp;id=85d6c939a5"
                          method="post"
                          id="mc-embedded-subscribe-form contactForm"
                          name="mc-embedded-subscribe-form"
                          className="contact-form shake validate"
                          target="_blank" 
                          data-toggle="validator"
                          novalidate
                        >
                          <div className="form-group">
                            <div className="controls">
                              <input
                                type="text"
                                name="FLNAME"
                                id="mce-FLNAME"
                                className="required form-control"
                                placeholder="Your Name"
                                required
                                data-error="Please enter your name"
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="controls">
                              <input
                                type="email"
                                className="email required form-control"
                                name="EMAIL"
                                id="mce-EMAIL"
                                placeholder="Your Email"
                                required
                                data-error="Please enter your email"
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="controls">
                              <input
                                type="phone"
                                name="PHONE"
                                className="phone form-control"
                                id="phone mce-PHONE"
                                placeholder="Your Phone"
                                required
                                data-error="Please enter your phone"
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="controls">
                              <textarea
                                id="message mce-MESSAGE"
                                rows="7"
                                placeholder="Your Massage"
                                name="MESSAGE"
                                className="required form-control"
                                required
                                data-error="Please enter your message"
                              ></textarea>
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div id="mce-responses" class="clear">
                            <div
                              class="response"
                              id="mce-error-response"
                              style={{ display: "none" }}
                            ></div>
                            <div
                              class="response"
                              id="mce-success-response"
                              style={{ display: "none" }}
                            ></div>
                          </div>
                          <div
                            style={{ position: "absolute", left: "-5000px" }}
                            aria-hidden="true"
                          >
                            <input
                              type="text"
                              name="b_870e1b447d1f93893227a9c52_85d6c939a5"
                              tabindex="-1"
                              value=""
                            />
                          </div>
                          <button
                            type="submit"
                            value="Subscribe"
                            name="subscribe"
                            id="submit mc-embedded-subscribe"
                            className="btn btn-success"
                          >
                            Send Message
                          </button>
                          <div
                            id="msgSubmit"
                            className="h3 text-center hidden"
                          ></div>
                          <div className="clearfix"></div>
                        </form>
                      </div>
                    </div>
                  </div> */}
                  <div className="row contact-info-row text-center wow fadeIn">
                    <div className="col-md-4 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".2s">
                        <i
                          className="fa fa-map-marker contact-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Address</h3>
                        <p>Parcelles u12, Dakar, Sénégal</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".4s">
                        <i
                          className="fa fa-envelope contact-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Email</h3>
                        <a
                          href="mailto:bayembayelo.bml@gmail.com"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontSize: "17px",
                          }}
                        >
                          bayembayelo.bml@gmail.com
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".6s">
                        <i
                          className="fa fa-mobile contact-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Téléphone</h3>
                        <a
                          href="tel:+221777619867"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontSize: "17px",
                          }}
                        >
                          +221 777619867
                        </a>
                      </div>
                    </div>
                    {/* <div className="col-md-3 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".6s">
                        <i
                          className="fa fa-television contact-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Portfolio</h3>
                        <a
                          href="https://portfolio-bml.vercel.app/"
                          target="newtab"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontSize: "17px",
                          }}
                        >
                          Visitez mon portfolio
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <div class="container-fluid map-col">
                  <div class="col-md-12 col-sm-12 map-col">
                    <div class="google-maps">
                      <div class="map-wrap">
                        <iframe
                          title="google-maps"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471220.5630603985!2d88.0495328251319!3d22.675752087592436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1653845709428!5m2!1sen!2sin"
                          width="100%"
                          height="450"
                          style={{ border: "0" }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default Home;
